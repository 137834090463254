<template>
  <b-row>
    <b-col>     
      <b-card>
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Ventas Rápida</h4>
            <div class="small text-muted">Interfaz para generar facturas mostrador</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <form-wizard  :title="wizard.title"
                      :subtitle="wizard.subtitle"
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="preSave"
                      class="crud-sales-wizard"
                      ref="wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="saleValidCrudGeneral">          
            <b-row>      
              <b-col md="4">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customers_id"
                            :where="conditionCustomersActive"
                            :key="crud.form.customers_id"
                            ref="searchCustomer" 
                            title="Buscador (F2)" />                 
              </b-col>                  
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es" :readonly="true" :disabled="disabledDates"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
              <b-col md="4">
                <b-form-group label="Fecha Vencimiento">
                  <b-form-datepicker size="sm" v-model="crud.form.date_expiration" placeholder="Fecha Vencimiento" local="es" :readonly="true" :disabled="disabledDates"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>              
            <b-row> 
              <b-col md="4">
                <FindObject render="search"
                            type="erpPointsSales" 
                            display="full_identification"
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="crud.form.points_sales_id" />                 
              </b-col>                              
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsPayment" 
                            @select-object="loadMethodsPayment($event)" 
                            :valueID="crud.form.methods_payment_id"
                            :key="crud.form.methods_payment_id" />                 
              </b-col>                 
              <b-col md="4">
                <FindObject render="search"
                            type="priceList" 
                            @select-object="loadPriceList($event)" 
                            :valueID="crud.form.price_list_id"
                            :key="crud.form.price_list_id"
                            :where="conditionPriceListActive" />                 
              </b-col>                           
            </b-row>
            <b-row v-if="parameters.haveAcopio && crud.form.selectTypeVoucher!='notacredito'">
              <b-col md="4">
                <FindObject render="search"
                            type="erpMethodsAcopio" 
                            @select-object="loadMethodsAcopio($event)" 
                            :valueID="crud.form.methods_acopio_id"
                            :key="crud.form.methods_acopio_id" 
                            :disabled="crud.form.id>0 || invoiceFrom" />                 
              </b-col>                 
            </b-row>            
            <b-row v-if="balanceCurrentAccount<0">
              <b-col>
                <b-alert show variant="danger">
                  Se encontró saldo a favor del cliente: <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(balanceCurrentAccount)}})</b>
                </b-alert>
              </b-col>
            </b-row>  

            <b-row v-if="crud.form.points_sales && !crud.form.points_sales.nd">
              <b-col>
                <div v-if="crud.form.points_sales.status">
                  <b-alert show variant="info" v-if="crud.form.points_sales.afip_production">
                    Punto de venta conectado a los servidores de AFIP <b>"EN PRODUCCIÓN"</b>
                  </b-alert>
                  <b-alert show variant="warning" v-else>
                    Punto de venta conectado a los servidores de AFIP <b>"HOMOLOGACION"</b>
                  </b-alert>                  
                </div>
                <div v-else>
                  <b-alert show variant="danger">
                    El punto de venta está <b>INACTIVO</b>
                  </b-alert>
                </div>
              </b-col>
            </b-row>              
          </tab-content>

          <tab-content title="Detalle" icon="fa fa-list" :before-change="saleValidCrudDetail">          
            <b-row>
              <b-col lg="12" md="12" sm="12">             
                  <Detail :customer_id="crud.form.customers_id" 
                          :price_list_id="crud.form.price_list_id"                   
                          @load-detail-products="setDetailItems($event, 'products')" 
                          @load-detail-products-compound="setDetailItems($event, 'productsCompound')" 
                          @load-detail-services="setDetailItems($event, 'services')" 
                          @load-detail-concepts="setDetailItems($event, 'concepts')" 
                          :detailProducts="crud.form.detailProducts"
                          :detailProductsCompound="crud.form.detailProductsCompound"
                          :detailServices="crud.form.detailServices"
                          :detailConcepts="crud.form.detailConcepts"
                          :hideConcepts="false"                           
                          :key="'detailInvoice_'+itemDetailForceUpdate"
                          :forceUpdate="itemDetailForceUpdate" 
                          :validItemDuplicatedProduct="false"
                          :validItemDuplicatedConcept="false"
                          :barcodeAddAutomaticProduct="true" 
                          :editPrice="!notEditPrice" />                  
                  
                  <b-row class="mt-0 pt-1 pl-3 pr-3 pb-1" align-v="end">
                    <b-col md="12" class="mb-0" v-if="showRemits">                      
                      <b-form-checkbox 
                        v-if="crud.form.types_vouchers && crud.form.types_vouchers.type_balance == '+'"
                        v-model="crud.form.generateRemits"
                        switch 
                        size="sm" 
                        class="pull-left">
                        <b>Generar Remito</b> <i>(Descuenta Stock)</i>
                      </b-form-checkbox>
                    </b-col>   

                    <b-col md="2">
                      <b-row>
                        <b-col md="5" class="crud-sales-totales-items-header">
                          ITEMS
                        </b-col>
                        <b-col md="7" class="crud-sales-counts-items">
                          <b>{{this.getCantItemSale}}</b>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6">
                      <div class="text-center"
                           v-if="crud.form.customers && crud.form.customers.data_fiscal && crud.form.customers.data_fiscal.fiscal_conditions_id == 5">

                        <b-link href="javascript:void(0);"                                 
                                @click="showCustomerIdentification = true"
                                v-if="!showCustomerIdentification">
                          <b-icon icon="chevron-double-right"></b-icon>
                            Modificar datos del consumidor final
                          <b-icon icon="chevron-double-left"></b-icon>
                        </b-link>

                        <b-card 
                          id="container_consumer_indentification"
                          header="Datos Consumidor Final"
                          header-bg-variant="dark"
                          header-text-variant="white"
                          header-tag="header"
                          bg-variant="light"
                          text-variant="dark"
                          class="mb-0"
                          no-body
                          v-if="showCustomerIdentification">
                          
                          <b-row class="pl-3 pr-3 pt-1 pb-2">
                            <b-col md="6">                
                              <b-form-group label="Nombre y Apellido" class="mb-0">
                                <b-form-input type="text"
                                              size="sm"
                                              v-model="crud.form.customer_name"
                                              required
                                              placeholder="Nombre y Apellido">
                                </b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="DNI" class="mb-0">
                                <b-form-input type="text"
                                              size="sm"
                                              v-model="crud.form.customer_document_number"
                                              required
                                              placeholder="DNI">
                                </b-form-input>
                              </b-form-group>                                
                            </b-col>      
                            <b-col md="12" class="mt-1">
                              <b-button variant="outline-dark" 
                                        size="sm" 
                                        class="w-100" 
                                        @click="showCustomerIdentification = false">
                                Listo
                              </b-button>
                            </b-col>              
                          </b-row>
                        </b-card>
                        
                      </div>
                      <div class="text-center mt-2">
                        <b-link href="#" @click="showSurchargeMethodPayment()">                          
                          Otros Métodos de Pago
                        </b-link>
                      </div>                        
                    </b-col>
                    <b-col md="4" v-if="getCurrencySale">
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.subtotal)}}
                        </b-col>                    
                      </b-row>
                      
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header crud-sales-totales-discounts-items">
                          DESCUENTO (%)
                        </b-col>                     
                        <b-col md="6" class="crud-sales-totales-items">
                          <b-row>
                            <b-col class="p-0">
                              <b-form-input v-model="crud.form.percentage_discount" 
                                    type="number" 
                                    min="0" 
                                    max="100"
                                    step="1" 
                                    class="form-control"
                                    size="sm"
                                    @change="validDiscounts()"/>                                                  
                            </b-col>
                            <b-col class="crud-sales-totales-discounts-items">
                              {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.discount)}}
                            </b-col>
                          </b-row>
                        </b-col>                    
                      </b-row> 
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          SUBTOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.subtotal_discount)}}
                        </b-col>                    
                      </b-row>                      
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          IVA
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.iva)}}
                        </b-col>                    
                      </b-row>            
                      <b-row>
                        <b-col md="6" class="crud-sales-totales-items-header">
                          TOTAL
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items-amount">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.total)}}</b>
                        </b-col>                    
                      </b-row>  
                      <b-row v-if="crud.form.methods_payment && parseFloat(crud.form.methods_payment.surcharge) > 0">
                        <b-col md="6" class="crud-sales-totales-items-header">
                          {{this.crud.form.methods_payment.name}} ({{ parseFloat(this.crud.form.methods_payment.surcharge) }})%
                        </b-col>
                        <b-col md="6" class="crud-sales-totales-items-amount" style="color: blue;">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(totals.total_surcharge)}}</b>
                        </b-col>                    
                      </b-row>                                                                                        
                    </b-col>
                  </b-row>
                  
              </b-col>        
            </b-row>
          </tab-content>

          <tab-content title="Asiento Contable" icon="fa fa-balance-scale" :before-change="salesValidCrudAccounting" v-if="showContabilidad"> 
            <div v-if="contabilidad.render" :key="'accounting_' + itemAccountingForceUpdate">
              <AccountingEntries  :typeDebe="contabilidad.typeDebe"
                                  :typeHaber="contabilidad.typeHaber"
                                  :defaultDebe="contabilidad.defaultDebe"
                                  :defaultHaber="contabilidad.defaultHaber"
                                  :reference="contabilidad.reference"
                                  :amountTotal="contabilidad.amountTotal"
                                  @getAsiento="getAsiento($event)" />         
            </div>
          </tab-content>

        </form-wizard>
      </b-card>

      <b-row>
        <b-col>  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>                     
              <b-col>
                <b-button type="button" variant="warning" size="sm" @click="cancel" class="pull-right">
                  <i class="fa fa-repeat"></i>
                  Reiniciar
                </b-button>                        
              </b-col>                     
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD FINANCE -->
      <b-modal v-model="modal.payment.active"
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="md">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.payment.title}}
        </div>

        <b-card>
          <b-row>
            <b-col md="12">
              <FinanceADD @getFinances="storeFinances($event)" 
                          v-if="crud.form.customers_id && crud.form.points_sales_id"
                          :points_sales="crud.form.points_sales"
                          :amount_impute="totals.total_surcharge"
                          :reset="finances.resetControl"
                          :default="finances.default"
                          :key="finances.forceUpdate" />    
            </b-col>
            <b-col md="12" class="mt-4">                                          
              <b-alert show variant="info" v-if="crud.form.payment && !parseFloat(crud.form.payment.total)">
                Si el <b>cliente paga</b> en el momento, cargue el <b>detalle de la cobranza.</b><br><br>         
                <i>En caso de no carga la cobranza, el comprobante quedará en cuenta corriente <b>pendiente de imputación</b></i>
              </b-alert>
              <div v-else>
                <b-alert show variant="success" v-if="parseFloat(crud.form.payment.total) == parseFloat(totals.total_surcharge)">
                  Comprobante Saldado                
                </b-alert>
                <div v-else>                
                  <b-alert show variant="warning" v-if="parseFloat(crud.form.payment.total) > parseFloat(totals.total_surcharge)">
                    El importe recibido es mayor al total de los comprobantes imputado.<br>
                    <b>Importe Imputado</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(this.totals.total_surcharge)}}<br>
                    <b>Importe Recibido</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(this.crud.form.payment.total)}}<br><br>
                    <i>El saldo quedará en cuenta corriente pendiente de imputación a favor del cliente</i>
                  </b-alert>
                  <b-alert show variant="danger" v-if="parseFloat(crud.form.payment.total) < parseFloat(totals.total_surcharge)">
                    El importe recibido es menor al total de los comprobantes imputado.<br>
                    <b>Importe Imputado</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(this.totals.total_surcharge)}}<br>
                    <b>Importe Recibido</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencySale}).format(this.crud.form.payment.total)}}<br><br>
                    <i>El comprobante quedará en cuenta corriente pendiente de imputación</i>
                  </b-alert>              
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancelPayment()">Cancelar</b-button>  
          <b-button variant="dark" @click="save()" title="Finalizar (CTRL + SHIFT + ENTER)">Finalizar</b-button>                            
        </div>      
      </b-modal>

      <!-- METODOS DE PAGO -->          
      <b-modal v-model="modal.method_payment.active"
              no-close-on-backdrop
              hide-header-close         
              header-bg-variant="dark"
              header-text-variant="white"
              size="lg">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.method_payment.title}}
        </div>

        <b-row>
          <b-col lg="12">            
            <MethodPaymentSurcharge v-if="crud.form.price_list && crud.form.price_list.currency" 
                                    :amount="totals.total" 
                                    :currency="crud.form.price_list.currency.code"
                                    @setMethodPayment="setMethodPayment($event)"/>          
                                    
            <MethodPaymentSurcharge v-else  
                                    :amount="totals.total" 
                                    currency="ARS"
                                    @setMethodPayment="setMethodPayment($event)"/>          
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.method_payment.active=false">Cancelar</b-button>          
        </div>        
      </b-modal>       

      <!-- CRUD EXTRA -->
      <b-modal v-model="modal.extra.active"
              no-close-on-backdrop
              hide-header-close         
              header-bg-variant="dark"
              header-text-variant="white">

        <div slot="modal-header">
          <Minimize />
          {{this.modal.extra.title}}
        </div>

        <b-row>  
          <b-col md="12" v-if="showShipping">
            <FindObject render="search"
                        type="suppliers" 
                        @select-object="loadShipping($event)" 
                        :where="conditionSuppliersShippingActive"
                        :valueID="crud.form.shipping_id"
                        tag="Transporte"                            
                        :key="crud.form.shipping_id"
                        :showAllRegister="true"/>
          </b-col>    

          <b-col lg="12">
            <b-form-group label="Observaciones Internas" 
                          description="Estas observaciones no se muestran en la impresión">
              <b-form-textarea                
                v-model="crud.form.observations_internal"
                placeholder="Ingresar las observaciones internas..."
                rows="3"
                max-rows="6"
                lazy
                ref="inputObservationsInternal"
              ></b-form-textarea>
            </b-form-group>
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.extra.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveAndPayment()" title="Guardar (CTRL + SHIFT + ENTER)">Guardar</b-button>          
        </div>        
      </b-modal>   

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import servicePrinterAPI from './servicesPrinter'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'
  import Detail from '@/components/inc/cpbtDetail/detail'
  import moment from 'moment'
  import AccountingEntries from '@/components/inc/accounting/entries'  
  import VoucherRelation from './inc/detailInvoice'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import FinanceADD from '@/components/inc/finances/add'  
  import Param from '@/config/parameters'  
  import MethodPaymentSurcharge from './inc/methodPayment'
  import Minimize from '@/components/inc/modal/minimize'

  export default {
    components: {
      FindObject,
      Printer,
      Detail,
      FormWizard,
      TabContent,     
      AccountingEntries, 
      VoucherRelation,
      FinanceADD,  
      MethodPaymentSurcharge,
      Minimize,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.VENTAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'salesQuick',
        },   
        parameters: {
          haveAcopio: Helper.hasParametersAccess(Param.P49),          
          freeAddTypeOperation: Helper.hasParametersAccess(Param.P61),
        },                     
        crud: {
          form: {
            id: 0,
            date: '',
            date_expiration: '',              
            types_vouchers_id: 0,
            types_vouchers: null,
            letter: '',
            number: 0,
            methods_payment_id: 0,
            methods_payment: null,
            price_list_id: 0,
            price_list: null,            
            points_sales_id: 0,
            points_sales: null,              
            observations: '',            
            staff_id: 0,
            sellers_id: 0,
            customers_id: 0,
            customers: null,   
            percentage_discount: 0,                                 
            detailProducts: [],
            detailProductsCompound: [],
            detailServices: [],
            detailConcepts: [],
            invoiceRelated: [],
            selectTypeVoucher: 'factura',
            selectDestination: 'default',
            accountingEntry: null,
            concepts: null,
            concepts_id: 0,
            remits: null,
            remits_id: 0,
            orders: null,
            orders_id: 0,     
            payment: [],
            showPayment: true,
            generateRemits: true,
            methods_acopio_id: 0,
            methods_acopio: null,   
            customer_name: '',
            customer_document_number: '',                        
            observations_internal: '',            
            shipping_id: 0,
            shipping: null,                  
          },          
          formPending: [],  
          print: {
            reference: '',
            id: 0,
            key: 0,
          }               
        },
        arr: {
          ivaCondition: [],
          financesCash: [],
        },
        wizard: {
          title: '',
        },
        totals: {
          subtotal: 0,
          discount: 0,
          iva: 0,
          total: 0,
          total_surcharge: 0,
          arrIva: [],                      
        },   
        modal: {
          payment: {
            active: false,
            title: '',
          },
          method_payment: {
            active: false,
            title: ''                        
          },
          extra: {
            active: false,
            title: ''            
          },                        
        },
        itemSelected: null,         
        itemDetailForceUpdate: 0,
        itemAccountingForceUpdate: 0,    
        contabilidad: {
          render: false,
          typeDebe:"",                                 
          typeHaber:"",
          defaultDebe:[],
          defaultHaber:[],
          reference:'',
          amountTotal:0
        },
        aux: {
          detailProducts: [],
          detailProductsCompound: [],
          detailServices: [],
          detailConcepts: [],          
        },
        calc: {
          discounts: 0,
        },       
        balanceCurrentAccount: 0,     
        showCustomerIdentification: true,    
        configurations: null,                
        finances: {
          default: null,
          staff: null,
          forceUpdate: 0,
          resetControl: false, 
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)      
      /* Fin configuracion */

      window.addEventListener("keydown", this.handlerAccessDirect);          
    },     
    destroyed() {
      window.removeEventListener("keydown", this.handlerAccessDirect);          
    },      
    mounted() {   
      this.loadConfigurations()       
    },    
    computed: {
      // MODULES
      moduleSellersActive() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.VENDEDORES) {                             
            status = true                       
          }
        })        
        return status
      },
      showContabilidad() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {                             
            status = true                       
          }
        })        
        return status
      },
      showRemits() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.REMITOS) {                             
            status = true                       
          }
        })        
        return status
      },      

      // OTHERS
      subtotalItemSelected() {
        if(this.itemSelected) {
          return parseFloat(this.itemSelected.amount_total)
        } else {
          return 0
        }
      },

      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },      
      conditionPriceListActive(){
        return [{field: 'active', condition: true}];
      },      
      conditionSuppliersShippingActive(){
        var condition = []
        condition.push({field: 'active', condition: true})
        condition.push({field: 'shipping', condition: true})
        return condition;
      },  

      // CANT / CURRENCY      
      getCantItemSale() {
        var cant1 = this.crud.form.detailProducts.length
        var cant2 = this.crud.form.detailProductsCompound.length
        var cant3 = this.crud.form.detailServices.length
        var cant4 = this.crud.form.detailConcepts.length

        return parseFloat(cant1 + cant2 + cant3 + cant4)
      },
      getCurrencySale() {        
        var currency = 'ARS'
        if(this.crud.form.price_list) {                    
          if(this.crud.form.price_list.currency) {                   
            currency = this.crud.form.price_list.currency.code            
          }
        }         
        return currency                      
      },
      
      // VOUCHER
      getRefVoucher() {        
        if(this.crud.form.types_vouchers && this.crud.form.points_sales && this.crud.form.number) {
          return this.crud.form.types_vouchers.name + ' '  + this.crud.form.points_sales.point_sale.toString().padStart(4,'0') + '-' + this.crud.form.number.toString().padStart(8,'0')  
        } else {
          return ''
        }      
      },

      // CONFIGURACION      
      getCurrency() {
        return 'ARS'
      },

      // VALUES DEFAULT
      customersDefault() {
        if(this.configurations) {
          return parseInt(this.configurations[1].value_number);
        } else {
          return 0;
        }
      },  
      notEditPrice() {
        if(this.configurations) {
          return this.configurations[5].value_boolean;
        } else {
          return false;
        }
      },
      showShipping() {                      
        if(this.configurations) {
          return this.configurations[6].value_boolean
        } else {
          return false
        }        
      },  
      disabledDates() {                      
        if(this.configurations) {
          return this.configurations[7].value_boolean
        } else {
          return false
        }        
      },  
      endPointESCPOS() {                      
        if(this.configurations) {
          return this.configurations[10].value_string
        } else {
          return false
        }     
      },

      // REMITO
      generateRemits() {
        let result = 'default'
        if(this.configurations) {
          if(this.configurations[2].value_string == 'always-remit') {
            result = 'si';
          }          
          if(this.configurations[2].value_string == 'never-remit') {
            result = 'no';
          }
        } 
        return result
      }      
    },
    watch: {        
      'crud.form.customers_id': function (newQuestion, oldQuestion) {         
        this.searchVoucherByCustomer()
      },
      'crud.form.points_sales_id': function (newQuestion, oldQuestion) {                
        this.searchVoucherByCustomer()
      },
      'crud.form.price_list_id': function (newQuestion, oldQuestion) {                
        this.detailForceUpdate();
      },            
      'crud.form.detailProducts': function (newQuestion, oldQuestion) {                                
        this.searchTotalsTemporary()
      },
      'crud.form.detailProductsCompound': function (newQuestion, oldQuestion) {       
        this.searchTotalsTemporary()
      },        
      'crud.form.detailServices': function (newQuestion, oldQuestion) {        
        this.searchTotalsTemporary()
      },
      'crud.form.detailConcepts': function (newQuestion, oldQuestion) {
        this.searchTotalsTemporary()
      }            
    },    
    methods: { 
      loadConfigurations() {
        var result = serviceAPI.obtenerConfiguracion()

        result.then((response) => {
          var data = response.data                  
          this.configurations = data             
          
          this.load()
          this.getIvaCondition()          
          this.showStaff()      
        })      
      },

      // SELECT
      loadCustomers (object) {        
        if(object){
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.points_sales_id) {
                this.crud.form.points_sales = object.data_fiscal.points_sales
                this.crud.form.points_sales_id = object.data_fiscal.points_sales_id
              }
            }

            if(object.data_fiscal.methods_payment_id) {
              if(!this.crud.form.methods_payment_id) {
                this.crud.form.methods_payment = object.data_fiscal.methods_payment
                this.crud.form.methods_payment_id = object.data_fiscal.methods_payment_id                            

                let days = parseInt(object.data_fiscal.methods_payment.term)
                this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')
              }
            }

            if(object.data_fiscal.methods_acopio_id) {
              if(!this.crud.form.methods_acopio_id) {
                this.crud.form.methods_acopio = object.data_fiscal.methods_acopio
                this.crud.form.methods_acopio_id = object.data_fiscal.methods_acopio_id
              }
            }     

            if(object.data_fiscal.shipping_id) {
              if(!this.crud.form.shipping_id) {
                this.crud.form.shipping = object.data_fiscal.shipping
                this.crud.form.shipping_id = object.data_fiscal.shipping_id
              }
            }                       
          }
          
          if(!this.crud.form.price_list_id) {
            if(object.price_list) {
              if(object.price_list.list) {              
                this.crud.form.price_list = object.price_list.list
                this.crud.form.price_list_id = object.price_list.list.id
              }
            }          
          }    
          
          this.getBalanceCurrentAccount()
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0

          this.balanceCurrentAccount = 0
        }
      }, 
      loadPointsSales (object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id       
          
          this.getBalanceCurrentAccount()
          this.getFinancesCash()       
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0

          this.getBalanceCurrentAccount()
        }
      },      
      loadMethodsPayment (object) {
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id             

          let days = parseInt(object.term)
          this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')                          
        } else {
          this.crud.form.methods_payment = null
          this.crud.form.methods_payment_id = 0
        }
      },
      loadMethodsAcopio (object) {
        if(object){
          this.crud.form.methods_acopio = object
          this.crud.form.methods_acopio_id = object.id             
        } else {
          this.crud.form.methods_acopio = null
          this.crud.form.methods_acopio_id = 0
        }
      },         
      loadPriceList (object) {
        if(object){
          this.crud.form.price_list = object
          this.crud.form.price_list_id = object.id
        } else {
          this.crud.form.price_list = null
          this.crud.form.price_list_id = 0
        }
      },        
      loadShipping (object) {        
        if(object){
          this.crud.form.shipping = object
          this.crud.form.shipping_id = object.id             
        } else {
          this.crud.form.shipping = null
          this.crud.form.shipping_id = 0
        }        

        if(this.$refs.inputObservationsInternal) {
          this.$refs.inputObservationsInternal.focus()
        }
      },   

      // AMOUNT CURRENT ACCOUNT
      getBalanceCurrentAccount() {
        var result = serviceAPI.filtrarCurrentsAccountsBalance({
          customers_id: this.crud.form.customers_id,
          points_sales_id: this.crud.form.points_sales_id, 
        })

        result.then((response) => {
          var data = response.data
          this.balanceCurrentAccount = data
        })
      },

      // ABM MAIN
      load(type='start') {        
        this.crud.form.id = 0           
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.sellers_id = 0
        this.crud.form.percentage_discount = 0                                 
        this.crud.form.detailProducts = []
        this.crud.form.detailProductsCompound = []
        this.crud.form.detailServices = []
        this.crud.form.detailConcepts = []
        this.balanceCurrentAccount = 0

        if(this.generateRemits == 'default') {
          this.crud.form.generateRemits = true
        } else {
          if(this.generateRemits == 'si') {
            this.crud.form.generateRemits = true
          } else if (this.generateRemits == 'no') {
            this.crud.form.generateRemits = false
          }
        }                  

        if(type == 'start') {             
          if(!this.customersDefault) {
            this.crud.form.customers_id = 0
            this.crud.form.customers = null               
          } else {
            this.crud.form.customers_id = this.customersDefault            
          }          
             
          this.crud.form.points_sales_id = 0
          this.crud.form.points_sales = null  
          this.crud.form.methods_payment_id = 0
          this.crud.form.methods_payment = null      
          this.crud.form.price_list_id = 0
          this.crud.form.price_list = null         
          this.crud.form.date = moment().format('YYYY-MM-DD')
          this.crud.form.date_expiration = moment().add(10,'d').format('YYYY-MM-DD')        
          this.crud.form.methods_acopio_id = 0
          this.crud.form.methods_acopio = null    
          this.crud.form.shipping_id = 0
          this.crud.form.shipping = null                                      
        } 

        if(type == 'restart') {
          this.crud.form.observations_internal = ''                            
          this.crud.form.shipping_id = 0
          this.crud.form.shipping = null          

          this.searchVoucherByCustomer()

          this.detailForceUpdate();
          this.forceUpdate();
        }
				
        this.wizard.title = "Nueva Factura"
        this.wizard.subtitle = ""
				
        this.crud.form.selectTypeVoucher = 'factura'
        this.crud.form.selectDestination = 'default'   
        
        this.crud.form.customer_name = ''
        this.crud.form.customer_document_number = ''            
      },

      preSave() {
        if(this.showShipping) {
          this.modal.extra.title = "Información Extra"
          this.modal.extra.active = true
        } else {
          this.saveAndPayment()
        }
      },
      saveAndPayment() {
        this.modal.extra.active = false

        this.finances.resetControl = true

        this.modal.payment.title = "Detalle del pago"
        this.modal.payment.active = true
      }, 
      cancelPayment() {        
        if(this.$refs.wizard) {            
          this.$refs.wizard.changeTab(2,1)
        }           

        this.modal.payment.active=false
      },                          
      save() {
        let loader = this.$loading.show();
        
        var result = serviceAPI.agregarComprobante(this.crud.form);
        
        result.then((response) => {
          var data = response.data
          this.sendPrinterSale(data)

          this.modal.extra.active = false

          this.modal.payment.active = false
          this.finances.resetControl = true

          loader.hide()        
          this.load('restart')      

          if(this.$refs.wizard) {            
            this.$refs.wizard.changeTab(2,1)
          }          
                        
          this.$awn.success("Comprobante generado con éxito");              
        })
        .catch(error => {       
          this.modal.extra.active = false

          this.modal.payment.active = false
          this.finances.resetControl = true
                                
          loader.hide()

          if(this.$refs.wizard) {            
            this.$refs.wizard.changeTab(2,1)
          }           

          this.$awn.alert(Error.showError(error));
        })              
      },          
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea reiniciar el comprobante?', {
          title: 'Reiniciar Comprobante',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'warning',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'warning',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.load('start')      

            if(this.$refs.wizard) {            
              this.$refs.wizard.changeTab(0,0)
            } 
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },              
      setDetailItems(event, type) { 
        if(type=="products") {
          this.crud.form.detailProducts = event                
        } 
        if(type=="productsCompound") {          
          this.crud.form.detailProductsCompound = event                
        }               
        if(type=="services") {
          this.crud.form.detailServices = event                
        } 
        if(type=="concepts") {
          this.crud.form.detailConcepts = event                
        }                 
      },
      detailForceUpdate() {
        this.itemDetailForceUpdate = this.itemDetailForceUpdate + 1
      },

      // VALID
      saleValidCrudGeneral() {        
        this.searchTotalsTemporary()  
        
        if(this.crud.form.customers_id && this.crud.form.points_sales_id  && this.crud.form.methods_payment_id) {
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }
          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }                        
          if(!this.crud.form.methods_payment_id) {
            this.$awn.alert("No se cargó el método de pago");
            return false;
          }                         
        }        
      },
      saleValidCrudDetail() {
        if( this.crud.form.detailProducts.length || 
            this.crud.form.detailProductsCompound.length || 
            this.crud.form.detailServices.length || 
            this.crud.form.detailConcepts.length) {
          this.getContabilidad()          
          return true;
        } else {
          this.$awn.alert("No se cargó ningún item");
          return false;
        }
      },
      salesValidCrudAccounting() {        
        if(this.crud.form.accountingEntry) {
          return true
        } else {
          this.$awn.alert("Revisar el Asiento Contable");
          return false
        }
      },    

      // SELECT VOUCHER 
      searchVoucherByCustomer() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherByCustomer({
              'customers_id': this.crud.form.customers_id,
              'point_sale_id': this.crud.form.points_sales_id,
							'reference': this.crud.form.selectTypeVoucher,
            });
            result.then((response) => {    
							this.crud.form.types_vouchers_id = response.data[0].id							
							this.searchVoucher()                          
            })
						.catch(error => {
							this.$awn.alert(Error.showError(error));
						})   						           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {    
            // simple                      
            var titleVoucher = ''
            titleVoucher = titleVoucher + this.crud.form.types_vouchers.name
            titleVoucher = titleVoucher +  ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            titleVoucher = titleVoucher +  '-' + this.crud.form.number.toString().padStart(8,'0')

            if(this.crud.form.points_sales && !this.crud.form.points_sales.nd) {
              if(this.crud.form.points_sales.status) {
                if(this.crud.form.points_sales.afip_production) {
                  this.wizard.title = titleVoucher + ' (CONECTADO AFIP)'
                } else {
                  this.wizard.title = titleVoucher + ' (CONECTADO AFIP - HOMOLOGACIÓN)'
                }                    
              } else {
                this.wizard.title = titleVoucher + ' (DESCONECTADO AFIP)'
              }
            } else {
              this.wizard.title = titleVoucher
            }
          }                   
        }
      },			
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherById({
              'points_sales_id': this.crud.form.points_sales_id,
              'types_vouchers_id' : this.crud.form.types_vouchers_id,
            });
            result.then((response) => {                      
              this.crud.form.types_vouchers = response.data.type_voucher
              this.crud.form.letter = response.data.type_voucher.letter                    
              this.crud.form.number = response.data.number
              
              if( this.crud.form.types_vouchers ) {              
                // simple
                var titleVoucher = ''
                titleVoucher = titleVoucher + this.crud.form.types_vouchers.name
                titleVoucher = titleVoucher +  ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
                titleVoucher = titleVoucher +  '-' + this.crud.form.number.toString().padStart(8,'0')

                if(this.crud.form.points_sales && !this.crud.form.points_sales.nd) {
                  if(this.crud.form.points_sales.status) {
                    if(this.crud.form.points_sales.afip_production) {
                      this.wizard.title = titleVoucher + ' (CONECTADO AFIP)'
                    } else {
                      this.wizard.title = titleVoucher + ' (CONECTADO AFIP - HOMOLOGACIÓN)'
                    }                    
                  } else {
                    this.wizard.title = titleVoucher + ' (DESCONECTADO AFIP)'
                  }
                } else {
                  this.wizard.title = titleVoucher
                }     
              }
                          
            })           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            // simple
            var titleVoucher = ''
            titleVoucher = titleVoucher + this.crud.form.types_vouchers.name
            titleVoucher = titleVoucher +  ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            titleVoucher = titleVoucher +  '-' + this.crud.form.number.toString().padStart(8,'0')

            if(this.crud.form.points_sales && !this.crud.form.points_sales.nd) {
              if(this.crud.form.points_sales.status) {
                if(this.crud.form.points_sales.afip_production) {
                  this.wizard.title = titleVoucher + ' (CONECTADO AFIP)'
                } else {
                  this.wizard.title = titleVoucher + ' (CONECTADO AFIP - HOMOLOGACIÓN)'
                }                    
              } else {
                this.wizard.title = titleVoucher + ' (DESCONECTADO AFIP)'
              }
            } else {
              this.wizard.title = titleVoucher
            }                             
          }                   
        }
      },
      searchTotalsTemporary() {
        if(this.crud.form.detailProducts || this.crud.form.detailProductsCompound || this.crud.form.detailServices || this.crud.form.detailConcepts) {
          var result = serviceAPI.obtenerVoucherTotalsTemporary({
            'header': this.crud.form,
            'detailsProducts': this.crud.form.detailProducts,
            'detailsProductsCompound': this.crud.form.detailProductsCompound,   
            'detailsServices': this.crud.form.detailServices,
            'detailsConcepts': this.crud.form.detailConcepts,
          });
          result.then((response) => {             
            var data = response.data   
            
            this.aux.detailProducts = data.detailProducts
            this.aux.detailProductsCompound = data.detailProductsCompound
            this.aux.detailServices = data.detailServices
            this.aux.detailConcepts = data.detailConcepts

            this.totals.subtotal = data.amount_net
            this.totals.discount = data.amount_discount
            this.totals.subtotal_discount = data.amount_subtotal
            this.totals.iva = data.amount_iva
            this.totals.total = data.amount_total
            this.totals.total_surcharge = data.amount_total_surcharge
            this.totals.arrIva = data.arrIva
          })           
        }                   
      },
      validDiscounts() {
        if(this.crud.form.percentage_discount=='') {          
          this.crud.form.percentage_discount = 0
        } else {          
          if( parseFloat(this.crud.form.percentage_discount) < 0 || 
              parseFloat(this.crud.form.percentage_discount) > 100 ) {            
            this.crud.form.percentage_discount = 0
          } 
        }       
        this.searchTotalsTemporary()
      },    
      

      // FINANCES
      getFinancesCash() {        
        var result = serviceAPI.obtenerCajas()        
        result.then((response) => {
          var data = response.data    
          this.arr.financesCash = []          

          data.forEach(cash => {
            let status1 = true
            let status2 = true
            
            if(!this.parameters.freeAddTypeOperation) {
              if(this.crud.form.points_sales) {                
                if(cash.nd != this.crud.form.points_sales.nd) {
                  status1 = false
                }
              }
            }

            if(status1) {           
              if(this.finances.staff && this.finances.staff.cash_id) {
                if(cash.id == this.finances.staff.cash_id) {
                  this.arr.financesCash = []
                  this.arr.financesCash.push(cash)
                  status2 = false
                }
              } else {
                if(this.crud.form.points_sales.cash_id) {
                  if(cash.id == this.crud.form.points_sales.cash_id) {
                    this.arr.financesCash = []
                    this.arr.financesCash.push(cash)
                    status2 = false
                  }
                }
              }

              if(status2) {
                this.arr.financesCash.push(cash)
              }
            }     
          })
        })   
      },            
      transformFinances(id) {
        this.financesForceUpdate()
        
        setTimeout(()=>{
          if(this.totals.total_surcharge) {            
            let cash = this.arr.financesCash.find(cash => parseInt(cash.keyaccess) === parseInt(id));            
            
            if(cash) {
              let cash_id = cash.id            

              let item = {
                cash: {
                  detail: [
                    {
                      cash: cash,
                      cash_id: cash_id,
                      total: this.totals.total_surcharge,
                    },              
                  ],
                  total: this.totals.total_surcharge,
                },
                cheques: {
                  detail: null,
                  total: 0,
                },
                total: this.totals.total_surcharge,
                totalFormat: Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrency}).format(this.totals.total_surcharge),
              }
            
              this.finances.resetControl = true
              this.finances.default = item        
            
              this.$awn.info("PAGO CON: <b>" + cash.name + '</b> - <b>' + item.totalFormat + '</b>');               

              this.storeFinances(item)
            } else {
              this.$awn.warning("Caja/Banco <b>NO DISPONIBLE</b>");               
            }
          }
        },100)
      },
      storeFinances(values) {        
        this.crud.form.payment = values           
        this.crud.form.payment.totalFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencySale}).format(this.crud.form.payment.total)

        this.finances.resetControl = false
      },      
      financesForceUpdate() {
        this.finances.forceUpdate = this.finances.forceUpdate + 1
      },
      showStaff()  {
        let staffID = Helper.getStaffId()
        var result = serviceAPI.mostrarStaff(staffID)

        result.then((response) => {
          var data = response.data
          if(data) {
            this.finances.staff = data
          } else {
            this.finances.staff = null
          }                     
        })          
      },      
    
      // RECARGO METODO DE PAGO
      showSurchargeMethodPayment() {
        this.modal.method_payment.title = "Métodos de Pago Disponibles"
        this.modal.method_payment.active = true          
      },
      setMethodPayment(object) {      
        if(object){
          this.crud.form.methods_payment = object
          this.crud.form.methods_payment_id = object.id             

          let days = parseInt(object.term)
          this.crud.form.date_expiration = moment(this.crud.form.date).add(days,'d').format('YYYY-MM-DD')                          
        }  
                
        this.searchTotalsTemporary()  
        this.modal.method_payment.active=false
      },

      // PRINT
      sendPrinterSale(item) { 
        if(this.endPointESCPOS) {          
          let result = serviceAPI.filtrarComprobante({
            id: item.id
          }, 1)        
          result.then((response) => {          
            let data = response.data
            let arrPrint = data.data
            
            if(arrPrint) {
              servicePrinterAPI.print(this.endPointESCPOS,arrPrint[0])
            }            
          })           
          .catch(error => {                      
            this.$awn.alert(ErrorToken.valid(error));
          })            
        } else {        
          this.crud.print.key=Math.random()
          this.crud.print.id=item.id
          this.crud.print.reference = item.reportDefault        
        }
      },
      
      // ACCESSOS DIRECTOS
      handlerAccessDirect(e) {                           
        // ctrl + shift + enter = 13
        if (e.ctrlKey && e.shiftKey && e.keyCode === 13) {
          e.preventDefault(); 
          
          if(this.modal.extra.active) {
            this.saveAndPayment()
          } else if(this.modal.payment.active){
            this.save()                  
          } else {
            if(this.$refs.wizard) {            
              this.$refs.wizard.nextTab();            
            }              
          }              
        }  
        
        
        // f2 = 113
        if (e.keyCode === 113) {           
          if(this.$refs.searchCustomer) {
            this.$refs.searchCustomer.openSearch()
          }
        }         

        // ctrl + shift + number (0-9)        
        if(this.modal.payment.active){
          let isKeyNumber = e.code.includes('Numpad') || e.code.includes('Digit')
          let isCtrl = e.ctrlKey
          let isShift = e.shiftKey  
          
          if (isKeyNumber) {
            if(isCtrl || isShift) {
              e.preventDefault();

              let id = parseInt(e.code.slice(-1))
              if(!isNaN(id)) {
                this.transformFinances(id)
              }                 
            }          
          } 
        }  
      },        

      /****************
       * CONTABILIDAD *
       * **************/      
      getContabilidad() {          
        this.contabilidad.render = false
        this.contabilidad.typeDebe = ""
        this.contabilidad.typeHaber = "",
        this.contabilidad.defaultDebe = []
        this.contabilidad.defaultHaber = []
        this.contabilidad.reference = this.getRefVoucher
        this.contabilidad.amountTotal = parseFloat(this.totals.total)
        
        // ASIENTO POR DEFECTO 
        if( this.crud.form.selectTypeVoucher == 'factura' || 
            this.crud.form.selectTypeVoucher == 'notadebito') {              
          this.contabilidad.defaultDebe = this.getContabilidadAsientoVenta('debe')
          this.contabilidad.defaultHaber = this.getContabilidadAsientoVenta('haber')
        } 
        // END
        
        this.contabilidad.render = true
        this.forceUpdate()

        setTimeout(()=>{
          if(this.crud.form.accountingEntry) {
            this.saveAndPayment()
          } 
        },100)       
      },

      getAsiento(object) {
        this.crud.form.accountingEntry = object
      },
      forceUpdate() {
        this.itemAccountingForceUpdate = this.itemAccountingForceUpdate + 1
      },

      getIvaCondition() {
        var result = serviceAPI.obtenerCondicionesIva()        
        result.then((response) => {
          var data = response.data    
          this.arr.ivaCondition = data          
        })   
      },  

      getContabilidadAsientoVenta(type) {                     
        if(type == 'debe') {        
          
          var arrDebe = []

          var totalConcepts = 0
          if(this.aux.detailConcepts) {
            this.aux.detailConcepts.forEach(element => {                   
              totalConcepts = totalConcepts + parseFloat(element.price) + parseFloat(element.iva)

              arrDebe.push(
                {
                  account: element.concepts.accounting_account_default,
                  amount: parseFloat(element.price) + parseFloat(element.iva),
                  disabled: true,
                }
              )
            });
          }

          if( parseFloat(this.totals.total) > totalConcepts ) {
            arrDebe.push(
              {
                account: this.crud.form.customers.accounting_account_default,
                amount: parseFloat(this.totals.total) - totalConcepts,
                disabled: true,
              }
            )
          }

          return arrDebe
        }        

        if(type == 'haber') {        
          var arrHaber = [
            {
              account: this.crud.form.customers.accounting_account_secondary,
              amount: parseFloat(this.totals.total) - parseFloat(this.totals.iva),
              disabled: true,
            }
          ]

          if(this.totals.arrIva) {
            Object.entries(this.totals.arrIva).forEach(([index1, element1]) => {              
              this.arr.ivaCondition.forEach(element2 => {              
                if(index1 == element2.id) {
                  arrHaber.push({
                    account: element2.accounting_account_default,
                    amount: parseFloat(element1),
                    disabled: true,                  
                  })
                }
              });
            })
            
          }     
          
          return arrHaber
        }        
      },          
    }
  }
</script>
<style>
  .crud-sales-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-sales-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-sales-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }  
  .crud-sales-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }  
  .crud-sales-totales-items-amount {
    font-size: 15px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;    
    border-width: 1px;    
  }    
  .crud-sales-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-sales-totales-discounts-items {
    padding-top: 5px;
  }  
  #view_list_sales .card-body {
    padding: 5px;
  } 
  .crud-sales-wizard .wizard-title {
    text-align: left;
  }
  .crud-sales-wizard .category {
    text-align: left;
  }  
  #container_consumer_indentification header {
    padding: 2px;
    font-weight:600;
  }
  #container_consumer_indentification legend {
    padding-bottom: 0px;
    text-align: left;
  }  
</style>